import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {PopoverController} from '@ionic/angular';
import {Benutzer} from '../../../../clients/vorlagen';

@Component({
    selector: 'app-aufgaben-berechtigungen-modal',
    templateUrl: './popover-component.page.html',
    styleUrls: ['./popover-component.page.scss'],
})
export class PopoverComponentPage implements OnInit {
    @Input() user: Benutzer;
    // site;

    constructor(
        private popoverController: PopoverController) { }

    ngOnInit() {
        // this.siteInfo = this.navParams.get('site');
        console.log(this.user);
    }

    logout() {
        // code for logout
        this.popoverController.dismiss(false);
    }

    zuordnungLoeschen() {
        this.popoverController.dismiss(true);
    }
}
