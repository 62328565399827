import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {TranslateModule} from '@ngx-translate/core';
import {CountdownTimerComponent} from "./countdown-timer/countdown-timer";
// import {LoginComponent} from '../auth/login/login.component';
// import {AzureLoginBrowserComponent} from '../auth/azure-login-browser/azure-login-browser.component';

@NgModule({
    declarations: [CountdownTimerComponent],
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        TranslateModule.forChild()
    ],
    exports: [CountdownTimerComponent]
})
export class ComponentsModule {
}