import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';


@Component({
    selector: 'countdown-timer',
    // template: `{{ displayTime }}`
    templateUrl: './countdown-timer.html',
    styleUrls: ['./countdown-timer.scss']
})
export class CountdownTimerComponent implements OnInit, OnDestroy {

    @Input() start;
    @Input() end;
    @Output() zeroTrigger;
    @Input() timeOnly;
    @Input() value = 'days';
    timer: any;
    displayTime: any;
    timerValue: any;
    days: any;
    hours: any;
    minutes: any;
    seconds: any;

    constructor(private el: ElementRef) {
        this.zeroTrigger = new EventEmitter(true);

        // if (this.color !== undefined && this.color !== null && this.color !== '') {
        //     this.color = 'white';
        // }
        // if (this.bgColor !== undefined && this.bgColor !== null && this.bgColor !== '') {
        //     this.bgColor = 'black';
        // }

    }

    ngOnInit(): void {
        this.timer = setInterval(() => {

            if (this.start) {
                this.displayTime = this.getTimeDiff(this.start, true);
                this.setTimeDiff(this.start, true);
            } else {
                this.displayTime = this.getTimeDiff(this.end);
                this.setTimeDiff(this.end);
            }
        }, 1000);
    }

    ngOnDestroy() {
        this.stopTimer();
    }

    private getTimeDiff(datetime, useAsTimer = false) {
        datetime = new Date(datetime).getTime();
        const now = new Date().getTime();

        if (isNaN(datetime)) {
            return '';
        }

        let milisecDiff = datetime - now;
        if (useAsTimer) {
            milisecDiff = now - datetime;
        }

        // Zero Time Trigger
        if (milisecDiff <= 0) {
            // TZ: Folgende Zeile entfernt, da sonst jede Sekunde ein emit geschickt würde nach Ablauf
            // this.zeroTrigger.emit("reached zero");
            return '00:00';
        }

        let dayString = '';
        const days = Math.floor(milisecDiff / 1000 / 60 / (60 * 24));
        const dateDiff = new Date(milisecDiff);
        if (days) {
            // dayString = (days > 1) ? this.twoDigit(days) + " Tage " : this.twoDigit(days) + " Tag ";
            dayString = (days > 1) ? days + ' Tage ' : days + ' Tag ';
        }

        const dayHours = days * 24;


        if (this.timeOnly) {
            const hours = dateDiff.getUTCHours() + dayHours;
            return this.twoDigit(hours) +
                ':' + this.twoDigit(dateDiff.getMinutes());
            // + ":" + this.twoDigit(dateDiff.getSeconds());
        } else {
            // Date() takes a UTC timestamp – getHours() gets hours in local time not in UTC. therefore we have to use getUTCHours()
            return dayString + this.twoDigit(dateDiff.getUTCHours()) +
                ':' + this.twoDigit(dateDiff.getMinutes());
            // + ":" + this.twoDigit(dateDiff.getSeconds());

        }
    }

    private setTimeDiff(datetime, useAsTimer = false) {
        datetime = new Date(datetime).getTime();
        const now = new Date().getTime();

        if (isNaN(datetime)) {
            return '';
        }

        this.days = 0;
        this.hours = 0;
        this.minutes = 0;
        this.seconds = 0;

        if (now < datetime) {

            let milisecDiff = datetime - now;
            if (useAsTimer) {
                milisecDiff = now - datetime;
            }

            const dateDiff = new Date(milisecDiff);

            const days = Math.floor(milisecDiff / 1000 / 60 / (60 * 24));
            const hours = dateDiff.getUTCHours();
            const minutes = dateDiff.getMinutes();
            const seconds = dateDiff.getSeconds();

            if (days > 0) { this.days = days; }
            if (hours > 0) { this.hours = hours; }
            if (minutes > 0) { this.minutes = minutes; }
            if (seconds > 0) { this.seconds = seconds; }

            // this.days = days;
            // this.hours = hours;
            // this.minutes = minutes;
            // this.seconds = seconds;
        }

        if (this.value === 'days') { this.timerValue = this.days; }
        if (this.value === 'hours') { this.timerValue = this.hours; }
        if (this.value === 'minutes') { this.timerValue = this.minutes; }
        if (this.value === 'seconds') { this.timerValue = this.seconds; }
    }


    private twoDigit(num: number) {
        return num > 9 ? '' + num : '0' + num;
    }

    private stopTimer() {
        clearInterval(this.timer);
        this.timer = undefined;
    }

}
