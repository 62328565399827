import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IonicModule} from '@ionic/angular';
import {PopoverComponentPage} from './popover-component.page';

@NgModule({
    imports: [
        CommonModule,
        IonicModule
    ],
    declarations: [PopoverComponentPage],
    entryComponents: [PopoverComponentPage],
    exports: [PopoverComponentPage]
})
export class PopoverComponentModule {
}
